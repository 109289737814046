<template>
  <div id="secondRight">
    <div class="right_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>设备概况</span>
      </div>
      <div class="deviceInfoBox">
        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">在线设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number">
                {{ buildingCounts ? buildingCounts.deviceOnline : "0" }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>

        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">离线设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number errorNumber">
                {{
                  buildingCounts
                    ? Number(buildingCounts.deviceSum) -
                      Number(buildingCounts.deviceOnline)
                    : "0"
                }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right_Second_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>监测数据</span>
      </div>

      <AnimTable />

      <!-- <div class="dv-scroll-board" rownum="12" style="margin-top: 0.3125rem">
        <div class="header" style="background-color: rgb(4, 102, 201)">
          <div
            align="center"
            class="header-item"
            style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
          >
            姓名
          </div>
          <div
            align="center"
            class="header-item"
            style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
          >
            体温
          </div>
          <div
            align="center"
            class="header-item"
            style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
          >
            健康码
          </div>
          <div
            align="center"
            class="header-item"
            style="height: 0.4375rem; line-height: 0.4375rem; width: 12vw"
          >
            测温时间
          </div>
        </div>
        <div class="rows" style="height: 46vh">
          <vue-seamless-scroll
            :data="this.$store.state.checkLogsList"
            :class-option="classOption"
          >
            <div
              class="row-item"
              style="
                height: 0.5625rem;
                line-height: 0.5625rem;
                background-color: rgb(9, 100, 191);
              "
              v-for="(item, index) in (this.$store.state.checkLogsList)"
              :key="index"
            >
              <div align="center" class="ceil" style="width: 6vw">
                <span class="span-rw">{{ item.realName }}</span>
              </div>
              <div align="center" class="ceil" style="width: 6vw">
                <span style="color: #52c41a"
                  ><span v-html="formatTem(item.temp)"></span
                ></span>
              </div>
              <div align="center" class="ceil" style="width: 6vw">
                <span :style="{ color: item.healthCode_cssStyle }">{{
                  item.healthCode_dictText || "-"
                }}</span>
              </div>
              <div align="center" class="ceil" style="width: 12vw">
                {{ item.checkTime }}
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import AnimTable from "../../components/echart/AnimTable"
export default {
  data() {
    return {
      loading: true,
      buildingCounts: this.$store.state.buildingCounts,
      classOption: {
        direction: 0,
      },
    };
  },
  components: {
    AnimTable
  },
  mounted() {

  },
  methods: {
    
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/thirdPage/rightGroup.scss";
</style>