// const serverUrl = 'http://139.224.116.231:8000/jeecg-boot' 
const serverUrl = '/jeecg-boot'
// const serverUrl = 'http://112.21.188.180:81/jeecg-boot'
// const serverUrl = 'http://192.168.1.18:9090/jeecg-boot'

const querySysCount = '/count/mmOfficeSysCount/query'  //系统统计-系统概况接口
const querySysPlaceCount = '/count/mmOfficeSysPlaceCount/query' //系统统计-重点场所分布
const querySysCheckCount = '/count/mmOfficeSysCheckCountDay/list' //系统统计-系统核验统计
const queryCheckLogList = '/count/mmOfficeSysCount/checkLogList' // 核验记录
const queryHealthCodeRatio = '/count/mmOfficeSysCount/healthCodeRatio' // 健康码比例
const queryBuildList = '/count/mmOfficeSysCount/buildingList' // 楼宇列表
const dictDataList = '/count/mmOfficeSysCount/dictDataList' //字典数据列表
const officeTreeList = '/sys/sysDepart/treeList' // 机构列表

//楼宇页面
const queryBuildingCount = '/count/mmBuildingSysCount/query' //楼宇概况
const queryBuildingCheckCountDay = '/count/mmBuildingSysCheckCountDay/list' //楼宇概况
const queryBuildingCheckCountHour = '/count/mmBuildingSysCheckCountHour/list' //楼宇概况

// 设备页面
const deviceList = '/count/mmBuildingSysCount/deviceList' //设备列表

// 系统参数

const sysInfo = '/count/mmOfficeSysCount/getSystemInfo'  // 获取安全运行天数、天气、状态


export {
    serverUrl,
    
    querySysCount,
    querySysPlaceCount,
    querySysCheckCount,
    queryCheckLogList,
    queryHealthCodeRatio,
    queryBuildList,

    dictDataList,
    officeTreeList,  
    queryBuildingCount,
    queryBuildingCheckCountDay,
    queryBuildingCheckCountHour,

    deviceList,

    sysInfo
    
}
