/* eslint-disable vue/require-v-for-key */
<template>
  <div id="thirdLeft">
    <div class="left_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>全部设备</span>
      </div>
      <div class="deviceList" v-if="deviceList">
        <div
          class="deviceList_item"
          v-for="(item, index) in deviceList"
          :key="index"
        >
          <img v-if="item.deviceTypeImage" :src="serverUrl + '/' + item.deviceTypeImage" alt="" />
          <img v-else src="../../assets/deviceImg.png" alt="" />
          <div>
            <div class="marquee_box thumbnail">
              <span>编号：{{ item.code }}</span>
            </div>
            <div>
              <span
                >状态：<span :style="{ color: item.deviceStatus_cssStyle }">{{
                  item.deviceStatus_dictText
                }}</span></span
              >
            </div>
            <div class="marquee_box">
              <div class="thumbnail">位置：{{ item.installAddress }}</div>
              <!-- <div class="marquee">
                <div class="marquee-wrap">
                  <span class="installAddress marquee-content"
                    ><span></span></span
                  >
                </div>
              </div> -->
            </div>
            <div class="layouts">
              <span>核验人次：<span style="color: #26befe;display: inline-block;width: 1rem" >{{ item.checkSum }}</span> </span>
              <span
                >异常：<span style="color: red">{{
                  item.checkErrorSum
                }}</span></span
              >
            </div>
            <div class="layouts">
              <span>今日核验：<span style="color: #26befe;display: inline-block;width: 1rem">{{ item.checkAdd }}</span></span>
              <span
                >异常：<span style="color: red">{{
                  item.checkErrorAdd
                }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { serverUrl } from "../../util/api";
export default {
  data() {
    return {
      loading: true,
      // deviceList: this.$store.state.deviceList,
      serverUrl: serverUrl,
    };
  },
  props:{
    deviceList:Array
  },
  components: {},
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/thirdPage/leftGroup.scss";
.thumbnail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>