
<template>
  <div class="dv-scroll-board" rownum="12" style="margin-top: 0.3125rem">
    <div class="header" style="background-color: rgb(4, 102, 201)">
      <div
        align="center"
        class="header-item"
        style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
      >
        姓名
      </div>
      <div
        align="center"
        class="header-item"
        style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
      >
        体温
      </div>
      <div
        align="center"
        class="header-item"
        style="height: 0.4375rem; line-height: 0.4375rem; width: 6vw"
      >
        健康码
      </div>
      <div
        align="center"
        class="header-item"
        style="height: 0.4375rem; line-height: 0.4375rem; width: 12vw"
      >
        测温时间
      </div>
    </div>
    <div class="rows" style="height: 43vh">
      <transition-group class="transition_box" name="list" tag="div" appear>
        <!-- key 不能用index，否则动画只作用最后一项-->
        <div
          class="row-item"
          style="
            height: 0.5625rem;
            line-height: 0.5625rem;
            background-color: rgb(9, 100, 191);
          "
          v-for="item in logList" :key="item.id"
        >
          <div align="center" class="ceil" style="width: 6vw">
            <span class="span-rw">{{ item.realName === '陌生人' ? '-':item.realName }}</span>
          </div>
          <div align="center" class="ceil" style="width: 6vw">
            <span style="color: #52c41a"
              ><span v-html="formatTem(item.temp)"></span
            ></span>
          </div>
          <div align="center" class="ceil" style="width: 6vw">
            <span :style="{ color: item.healthCode_cssStyle }">{{
              item.healthCode_dictText || "-"
            }}</span>
          </div>
          <div align="center" class="ceil" style="width: 12vw">
            {{ item.checkTime }}
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
 
<script>
import { serverUrl } from "../../util/api";
import { formatTem } from "../../util/config";

export default {
  data() {
    return {
      logList: [],
      serverUrl: serverUrl,
      formatTem: formatTem,
    };
  },
  watch: {
    "$store.state.checkLogsList": {
      handler: function (newVal) {
        if (newVal) {
          var list = newVal.map((item) => {
            return {
              ...item,
            };
          });
          let _this = this;
          this.logList.unshift(...list);
          if (this.logList && this.logList.length >= 15) {
            _this.logList = _this.getMap(this.logList.slice(0, 15));
          }
        }
      },
    },
  },

  methods: {
    getMap(arr) {
      let map = new Map();
      for (let item of arr) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      return [...map.values()];
    },
  },
};
</script>
 
<style>
.rolling {
  height: 60vh;
  width: 90%;
  margin: 0 auto;
  padding: 0.125rem 0;
}
.transition_box {
  height: 43vh;
  position: relative;
}

.rolling_box {
  /* position: absolute; */
  /* height: 60vh;
  width: 90%; */

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.125rem;
  transition: all 2s;
}
.faceIamge {
  width: 1.25rem;
  height: 1.5rem;
  border: 0.0375rem solid #01adff;
  object-fit: cover;
}
.face_text {
  /* overflow: hidden; */
  width: 15vw;
  color: #fff;
  line-height: 0.3125rem;
  margin-left: 0.25rem;
}
.temperature {
  display: flex;
}
.healthCode {
  margin-left: 0.1875rem;
}
.thumbnail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.list-leave-active {
  position: absolute;
}

.list-enter {
  opacity: 0;
  transform: translateY(-0.5625rem);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(0.5625rem);
}
.list-move {
  transition: all 2s;
}

.row-item {
  background-color: rgba(0, 0, 0, 0) !important ;
}
.header,
.header-item {
  background-color: #0466c9;
  color: #afd4f6;
  font-size: 0.15rem !important;
}
</style>