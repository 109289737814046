<template>
  <div id="index">
    <div class="bg">
      <div>
         <!-- 版本切换 -->
        <div class="page-top-title">常州市智慧通行数字哨兵管控平台</div>
        <!-- <div class="page-top-title"> <img style="width:0.375rem;margin-right: 0.125rem;" src="../../assets/logodsj.png" /> <div>常州大数据数字哨兵管控平台</div></div> -->
        <div class="title_left_top">
          <div class="logo_text">
            <!-- 版本切换 -->
            <img src="../../assets/log.png" />
            <span>富敏人工智能</span>

            <!-- <img src="../../assets/logodsj.png" />
            <span>常州大数据数字哨兵管控平台</span> -->
          </div>
          <div class="run_day_num">
            <span class="small_title">平台安全运行</span>
            <span class="day_num">{{
              this.$store.state.sysInfo &&
              this.$store.state.sysInfo.systemRunTime
                ? this.$store.state.sysInfo.systemRunTime
                : "暂无"
            }}</span>
          </div>

          <div class="title_status_box">
            <span class="title_status">状态</span>
            <span class="content_status">{{
              this.$store.state.sysInfo &&
              this.$store.state.sysInfo.systemRunningStatus
                ? this.$store.state.sysInfo.systemRunningStatus
                : ""
            }}</span>
          </div>

          <div class="title_status_box">
            <span
              class="title_status"
              style="color: #26befe; cursor: pointer"
              @click="goHomepage()"
              >返回首页</span
            >
          </div>
        </div>
        <div class="title_right_top">
          <div class="weather_box">
            <span class="title_weather buildNameStyle">{{
              this.$store.state.buildName ? this.$store.state.buildName : "暂无"
            }}</span>
            <span class="title_weather">天气</span>
            <span class="weather_status">{{
              this.$store.state.sysInfo && this.$store.state.sysInfo.weatherInfo
                ? this.$store.state.sysInfo.weatherInfo.wea
                : "暂无"
            }}</span>
          </div>

          <div class="date_box">
            <span class="title_date">{{ day }} {{ week }}</span>
          </div>

          <div class="time_box">
            <span class="title_time">{{ times }}</span>
          </div>

          <div class="time_box">
            <el-tooltip
              class="item"
              effect="dark"
              content="退出登录"
              placement="left-end"
            >
              <i class="el-icon-switch-button" @click="loginOut"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <leftGroup :deviceList="deviceList" />
      <rightGroup />
    </div>
  </div>
</template>
<script>
import rightGroup from "./rightGroup.vue";
import leftGroup from "./leftGroup.vue";
import moment from "dayjs";
import { deviceList, queryCheckLogList } from "../../util/api";
// 时间计时器
let dateTimer = null;

// 数据刷新计时器
let dataRefreshTimer = null;

// 刷新间隔
const refreshInterval = 10000;
export default {
  data() {
    return {
      loading: true,
      times: "", //格式化之后的当前时间
      day: "",
      week: "",
      buildingCounts: null,
      deviceList: [],
      startTime: null,
    };
  },
  components: {
    leftGroup,
    rightGroup,
  },
  watch: {
    "$store.state.sysOrgCode": {
      //监听 store 中值变化请求接口进行刷新
      handler: function (newVal) {
        if (newVal) {
          this.$store.commit("setCheckLogsList", []);
          this.startTime = null;
          this.startDataRefreshTimer();
        }
      },
    },
  },
  created() {
    this.startDataRefreshTimer();
  },
  mounted() {
    // 面板时间计时器开始
    this.startDateTimer();

    if (this.$store.state.sysOrgCode) {
      this.startDataRefreshTimer();
    }
  },

  destroyed() {
    console.log("============ destroyed ================");
    dateTimer && clearInterval(dateTimer);
    dataRefreshTimer && clearInterval(dataRefreshTimer);
    this.$store.commit("setCheckLogsList", []);
  },
  methods: {
    startDateTimer() {
      dateTimer = setInterval(() => {
        let wk = new Date().getDay();
        let year = new Date().getFullYear(); //获取当前时间的年份
        let month = this.zerouze(new Date().getMonth() + 1); //获取当前时间的月份
        let day = this.zerouze(new Date().getDate()); //获取当前时间的天数
        let hours = new Date().getHours(); //获取当前时间的小时
        let minutes = new Date().getMinutes(); //获取当前时间的分数
        let seconds = new Date().getSeconds(); //获取当前时间的秒数
        let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        let week = weeks[wk];

        //当小于 10 的是时候，在前面加 0
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        //拼接格式化当前时间
        this.day = year + "-" + month + "-" + day;
        this.times = hours + ":" + minutes + ":" + seconds;
        this.week = week;
      }, 1000);
    },

    loginOut() {
      const that = this;
      this.$confirm("此操作将注销此登录账户并回到登录页面, 是否继续?", "提示", {
        confirmButtonText: "确定登出",
        cancelButtonText: "取消操作",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("resetState", {
            place: null, // 首页产所类型
            sysOrgCode: null, //首页所属机构
            checkLogTime: null,
            secondCheckLogTime: null,
            userId: null, // 获取
            buildName: null,
            sysCounts: null, // 系统概况
            placeCounts: null, // 重点场所
            checkCounts: null, // 核验人次
            checkLogs: [], // 实时测温
            healthCodeRadio: null, // 健康码比例
            buildList: [], // 楼宇列表
            isChangeOrgCode: false,
            buildId: null, //楼宇Id
            buildingCounts: null, //第二个屏幕，楼宇概况数据
            buildingCheckCountHour: [], //第二屏，时段核验趋势
            buildingCheckCountDay: [], // 第二屏，核验人次趋势

            deviceList: [], //第三屏，设备列表
            checkLogsList: [], // 第三屏，实时测温列表

            sysInfo: {
              weatherInfo: {
                wea: "暂无",
              },
            },
          });
          localStorage.removeItem("pro__Access-Token");
          localStorage.clear();
          that.$router.replace("/");
          this.$message({
            type: "success",
            message: "登出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作已取消",
          });
        });
    },

    startDataRefreshTimer() {
      var that = this;
      dataRefreshTimer && clearInterval(dataRefreshTimer);

      var onRefresh = function () {
        // 执行 获取数据
        console.log("============ onRefresh ================");
        that.refreshSysCheckLog();
        that.refreshDeviceList();
        return onRefresh; //定时器会立即执行一次 再定时执行
      };

      dataRefreshTimer = setInterval(onRefresh(), refreshInterval);
    },
    /**
     * 刷新 实时测温
     */
    async refreshSysCheckLog() {
      this.$http
        .get(queryCheckLogList, {
          sysOrgCode: this.$store.state.sysOrgCode
            ? this.$store.state.sysOrgCode + "*"
            : null,
          buildingId: this.$store.state.buildId,
          createTime_begin: this.startTime
            ? moment(this.startTime).format("YYYY-MM-DD HH:mm:ss")
            : null,
          createTime_end: this.startTime
            ? moment(Number(this.startTime) + Number(refreshInterval)).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          column: "checkTime",
          order: "desc",
        })
        .then((data) => {
          if (data && data.code == 200) {
            this.$store.commit("setCheckLogsList", data.result.records);
            this.startTime = data.timestamp;
          } else {
            this.$message.error(data.message);
          }
        });
    },

    /**
     * 刷新 设备列表
     */
    async refreshDeviceList() {
      this.$http
        .get(deviceList, {
          sysOrgCode: this.$store.state.sysOrgCode
            ? this.$store.state.sysOrgCode + "*"
            : null,
          buildingId: this.$store.state.buildId,
        })
        .then((data) => {
          if (data && data.code == 200) {
            this.deviceList = data.result.records;
            this.$store.commit("setDeviceList", data.result.records);
          } else {
            this.$message.error(data.message);
          }
        });
    },
    // 月份不足补0
    zerouze(obj) {
      if (obj < 10) {
        return "0" + obj;
      } else {
        return obj;
      }
    },
    goHomepage() {
      this.$router.go(-2);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/index";
.buildNameStyle {
  margin-right: 0.375rem;
}
.el-icon-switch-button {
  color: #fff;
}

// 版本切换
.page-top-title {
  letter-spacing: 0.15rem !important;
   display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}
</style>