

const tooltipConfig = {
    confine:true,
    transitionDuration: 0,
    backgroundColor:'rgba(8,28,74,0.7)',
    borderColor:'rgba(80,158,240, 0.8)',
    extraCssText: 'box-shadow: 0px 0px 10px rgba(80,158,240, 0.8);', // 浮层添加阴影
    borderWidth: 1,
    padding:5,
    textStyle:{
      color:'#5AC8EB',
      fontSize:12,
      textShadowColor:'transparent', 
      lineHeight:20,
    },
}

const echartsLoop = function (echartsName,time,seriesIndex) {
  var currentIndex = -1;
  echartsLoopTimes && clearInterval(echartsLoopTimes);
  const  echartsLoopTimes =  setInterval(function () {
    var dataLen = echartsName._model.option.series[seriesIndex].data.length;
    // 取消之前高亮的图形
    echartsName.dispatchAction({
      type: "downplay",
      seriesIndex: seriesIndex, //表示series中的第几个data数据循环展示
      dataIndex: currentIndex,
    });
    currentIndex = (currentIndex + 1) % dataLen; //+1表示每次跳转一个
    // 高亮当前图形
    echartsName.dispatchAction({
      type: "highlight",
      seriesIndex: seriesIndex,
      dataIndex: currentIndex,
    });
    // 显示 tooltip
    echartsName.dispatchAction({
      type: "showTip",
      seriesIndex: seriesIndex,
      dataIndex: currentIndex,
    });
  }, time);
}


const formatTem = (value) =>{
  if(Number(value)===0){
    return `<span style='color:#abb8c3'>未测温</span>`
  }else if(Number(value) >= 37.3){
    return `<span style='color:red'>${value} ℃</span>`
  }else {
    return `<span style='color:#52C41A'>${value} ℃</span>`
  }
}



export {
    tooltipConfig,
    echartsLoop,
    formatTem
}
